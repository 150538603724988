<template>
  <section
    id="home"
    class="pa-0"
  > 
    <guest-mobile-background />
    <v-layout
      row
      wrap>
      <guest-fields 
        :logo_img="logo_img"
        :smUp="smUp"
      />
      <guest-banner 
        v-if="smUp"
        :image="background_img"
      />
    </v-layout>
  </section>
</template>

<script>
  export default {
    metaInfo () {
      return {
        title: 'Panel de administración - Guest'
      }
    },

    components: {
      //new version components
      GuestBanner: () => import('@/components/sections/Guest/GuestBanner'),
      GuestFields: () => import('@/components/sections/Guest/GuestFields'),
      GuestMobileBackground: () => import('@/components/sections/Guest/GuestMobileBackground'),
    }, 
    data: () => ({
      background_img: '/static/background_image.png',
      logo_img: '/static/image_logo.png',
      company_name: 'Instituto de Psicoterapia',
      toggle_bg_effect: false
    }),

    computed: {
      smUp () {
        return this.$vuetify.breakpoint.smAndUp
      },
      cardFeatures () {
        return []
      },
      listFeatures () {
        return []
      },
      testimonials () {
        return []
      }
    }
  }
</script>
